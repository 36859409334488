import retrieveLocalData from "./retrieveLocalData";
import storeLocalData from "./storeLocalData";

export default (
    destinationId, {
      orderNum, customerNum, date, waybillNumber, customerEmail, pickupEmail, unloadEmail
    }
  ) => {
    if (!destinationId || !orderNum || !customerNum) {
      console.log("Cannot update destinationOrderCustomer. destinationId, orderNum and customerNum are required. Destination id: ", destinationId);
      return;
    }

    // Use cache as basis
    let destinationOrderCustomer = {
      ...retrieveLocalData("destinationOrderCustomer")
    };

    let orderCustomerItems = destinationOrderCustomer[destinationId] || [];
    let existingOrderCustomer = orderCustomerItems.find(obj => obj.orderNum === orderNum);
    if (existingOrderCustomer) {
      existingOrderCustomer.date = date;
      existingOrderCustomer.waybillNumber = waybillNumber;
      existingOrderCustomer.customerEmail = customerEmail;
      existingOrderCustomer.pickupEmail = pickupEmail;
      existingOrderCustomer.unloadEmail = unloadEmail;
    } else {
      orderCustomerItems.push({
        "orderNum": orderNum,
        "customerNum": customerNum,
        "date": date,
        "waybillNumber": waybillNumber,
        "customerEmail": customerEmail,
        "pickupEmail": pickupEmail,
        "unloadEmail": unloadEmail
      })
    }

    destinationOrderCustomer[destinationId] = orderCustomerItems;

    storeLocalData("destinationOrderCustomer", destinationOrderCustomer);
};

