import React, { useEffect, useRef, useState } from 'react';
import { withApollo } from "react-apollo";
import Loading from '../../layout/Loading';
import Button from "../../input/Button";
import Modal from "../../layout/Modal";
import "./WaybillPhotoCapture.css";
import ReactImagePickerEditor from 'react-image-picker-editor';
import {logEvent} from "../../../api/graphql/logEvent";

import 'react-image-picker-editor/dist/index.css';
import { getImages, setImages } from '../../../utils/db';

function WaybillPhotoCapture(props) {
  const {
    destinationId,
    isOnline,
    orderNum,
  } = props;

  const imageTotalSizeLimit = 3584000; // AWS Amplify MappingTemplate has has internal 3.5MB hard-limit
  const imageDefaultLength = 75;

  const config = {
    borderRadius: '8px',
    language: 'en',
    width: '300px',
    height: '360px',
    objectFit: 'contain',
    aspectRatio: 4 / 3,
    compressInitial: 50,
    hideDownloadBtn: true,
    hideEditBtn: true
  };

  const [img1Src, setImg1Src] = useState(null);
  const img1Ref = useRef(null);

  const [img2Src, setImg2Src] = useState(null);
  const img2Ref = useRef(null);

  const [img3Src, setImg3Src] = useState(null);
  const img3Ref = useRef(null);

  const [img4Src, setImg4Src] = useState(null);
  const img4Ref = useRef(null);

  const [img5Src, setImg5Src] = useState(null);
  const img5Ref = useRef(null);

  const [img6Src, setImg6Src] = useState(null);
  const img6Ref = useRef(null);

  const [imagesArray, setImagesArray] = useState([null,null,null,null,null,null]);

  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [validationFailedReason, setValidationFailedReason] = useState('');

  useEffect(() => {
    const fetchImages = async () => {
      const storedImages = await getImages(destinationId, orderNum);
      handleImages(storedImages)
    };

    if (orderNum !== null) {
      fetchImages();
    }
  }, []);

  const handleImages = (images) => {
    if (images) {
      setImagesArray(images);
      setImg1Src(images[0]);
      setImg2Src(images[1]);
      setImg3Src(images[2]);
      setImg4Src(images[3]);
      setImg5Src(images[4]);
      setImg6Src(images[5]);
    }
  }

  let showWaybillPhotoCapture = true;

  const logImageChange = async (newDataUri, errorMsg) => {
    const imageData = newDataUri ? newDataUri.slice(-100) : '<deleted previously added image>';
    const message = errorMsg
      ? `LOG: An error occurred when adding image, error:${errorMsg}, customerNum: ${props.customerNum}, waybillNum: ${props.waybillNum}, imageData: ${imageData}`
      : `LOG: Image was added. CustomerNum: ${props.customerNum}, waybillNum: ${props.waybillNum}, imageData: ${imageData}`;

    try {
      await props.client.mutate({
        mutation: logEvent,
        variables: {
          message: message
        },
      });
    } catch (error) {
      console.error("Error logging image", error);
    }
  }


  const onImageChanged = async (newDataUri, changeIndex) => {
    const images = imagesArray.length === 0 ? ['','','','','',''] : [...imagesArray];
    if (newDataUri !== "" && images[changeIndex - 1] !== newDataUri) {
      images[changeIndex - 1] = newDataUri;

      await setImages(destinationId, orderNum, images);
      handleImages(images);

      let imageTotalSize = 0;
      const imageRefs = [img1Ref, img2Ref, img3Ref, img4Ref, img5Ref, img6Ref];
      imageRefs.forEach(ref => {
        if (ref.current && ref.current.src) {
          imageTotalSize += ref.current.src.length * 0.75;
        }
      });

      let errorMsg = undefined;

      if (!isOnline) {
        errorMsg = 'Kuljetusasiakirjakuvien lähetys vaatii verkkoyhteyden. Yritä myöhemmin uudelleen.';
        setValidationFailedReason(errorMsg);
        setNotification(true);
      } else if (imageTotalSize > imageTotalSizeLimit) {
        errorMsg = 'Kuljetusasiakirjakuvien yhteiskoko on liian suuri (yli 3.5MB). Pienennä kokoa ja yritä uudelleen.';
        setValidationFailedReason(errorMsg);
        setNotification(true);
      }

      await logImageChange(newDataUri, errorMsg);
    }
  }

  return (
    <div className="waybillImages">
      {showWaybillPhotoCapture ? (<>
        <h2>Kuljetusasiakirjakuvat</h2>
        {loading ? (
          <Loading>
            Lähetetään kuljetusasiakirjakuvia...
          </Loading>
        ) : (<>
          <div className="imageFrameContainer">
            <div className="container">
              <h3 className="imageHeader">Kuva 1</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img1Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 1);
                }}
              />
              <img
                src={img1Src}
                ref={img1Ref}
                alt="image1"
                style={{display: 'none'}}
              />
            </div>
            <div className="container">
              <h3 className="imageHeader">Kuva 2</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img2Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 2);
                }}
              />
              <img
                src={img2Src}
                ref={img2Ref}
                alt="image2"
                style={{display: 'none'}}
              />
            </div>
            <div className="container">
              <h3 className="imageHeader">Kuva 3</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img3Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 3);
                }}
              />
              <img
                src={img3Src}
                ref={img3Ref}
                alt="image3"
                style={{display: 'none'}}
              />
            </div>
            <div className="container">
              <h3 className="imageHeader">Kuva 4</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img4Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 4);
                }}
              />
              <img
                src={img4Src}
                ref={img4Ref}
                alt="image4"
                style={{display: 'none'}}
              />
            </div>
            <div className="container">
              <h3 className="imageHeader">Kuva 5</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img5Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 5);
                }}
              />
              <img
                src={img5Src}
                ref={img5Ref}
                alt="image5"
                style={{display: 'none'}}
              />
            </div>
            <div className="container">
              <h3 className="imageHeader">Kuva 6</h3>
              <ReactImagePickerEditor
                config={config}
                imageSrcProp={img6Src}
                imageChanged={(newDataUri) => {
                  onImageChanged(newDataUri, 6);
                }}
              />
              <img
                src={img6Src}
                ref={img6Ref}
                alt="image6"
                style={{display: 'none'}}
              />
            </div>
          </div>
        </>)}

        {notification ? (
          <Modal uncloseable={true}>
            <h3>KULJETUSASIAKIRJAKUVAT</h3>
            <>
              <span>{validationFailedReason}</span>
              <br />
              <br />
              <Button
                onClick={() => {
                  setNotification(false);
                }}
              >OK</Button>
            </>
          </Modal>
        ) : (<></>)}

      </>) : (<></>)}
    </div>
  );
}

export default withApollo(WaybillPhotoCapture);
