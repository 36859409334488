import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import Field from "./GreenField";

import styles from "./LoadingDetailsInput.module.scss";
import amountValidation from "../../containers/data/utils/amountValidation";

class UnloadingDetailsInput extends React.Component {
  componentDidMount() {
    // store the initial values as user input to enable validation
    const {
      waybillNum,
      actualAmount,
      weightNoteNumberUnloading,
      container1Unload,
      container2Unload,
      container3Unload,
      container4Unload
    } = this.props;
    this.props.onChange({
      waybill: waybillNum,
      amountLoaded: actualAmount,
      weightNoteNumberUnloading: weightNoteNumberUnloading,
      container1Unload: container1Unload,
      container2Unload: container2Unload,
      container3Unload: container3Unload,
      container4Unload: container4Unload
    });
  }

  validate(values) {
    // This function call updates the state of the parent component 
    // so the form input is stored correctly.
    this.props.onChange(values);
    let errors = {
      amountLoaded: this.validateAmount(values.amountLoaded),
      weightNoteNumberUnloading: false
      //waybill: this.validateWaybill(values.waybill)
    };
    return errors;
  }

  validateWaybill(value){
    return value && value.toString().length > 0 ? undefined : "Pakollinen";
  }

  validateAmount(value){
    const {loadUnit} = this.props;
    return amountValidation(value, loadUnit);
  }

  render() {
    const {
      loadUnit,
      waybillNum,
      actualAmount,
      weightNoteNumberUnloading,
      container1Unload,
      container2Unload,
      container3Unload,
      container4Unload
    } = this.props;
    return (
      <Formik
        initialValues={{
          waybill: waybillNum || "",
          amountLoaded: !isNaN(actualAmount) ? actualAmount : "", 
          weightNoteNumberUnloading: weightNoteNumberUnloading || "",
          container1Unload: container1Unload || "",
          container2Unload: container2Unload || "",
          container3Unload: container3Unload || "",
          container4Unload: container4Unload || ""
        }}

        // This is hack to store the values entered in the Formik form to 
        // the context of the main order - it's not actually for validating 
        // the form
        validate={values => this.validate(values)} 
      >
        {formik => {
          const { 
            //isSubmitting, 
            values,
            //errors,
            //touched
            //setFieldError
          } = formik;
          let { errors } = formik;

          errors = errors || {};
          //errors.waybill = this.validateWaybill(values.waybill);
          errors.amountLoaded = this.validateAmount(values.amountLoaded);
          
          return (
            <Form>
              <div className={styles.fields}>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Rahtikirja</span>
                    <Field
                      id="waybill"
                      maxLength={20}
                      readOnly={true}
                      disabled={true}
                      valid={!errors.waybill}
                    />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Purettu</span>
                    <Field
                      id="amountLoaded"
                      type="text"
                      unit={loadUnit}
                      valid={!errors.amountLoaded}
                      inputMode="text"
                      pattern="[0-9,.]*"
                    />
                    {loadUnit && <span className={styles.unitLabel}>{loadUnit}</span>}
                  </div>
                  {errors && errors.amountLoaded && <span className={styles.errorLabel}>{errors.amountLoaded}</span>}
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Purun punnitustosite</span>
                    <Field
                      id="weightNoteNumberUnloading"
                      valid={!errors.weightNoteNumberUnloading}
                      maxLength={20}
                    />
                  </div>
                  {errors && errors.weightNoteNumberUnloading && <span className={styles.errorLabel}>{errors.weightNoteNumberUnloading}</span>}
                </div>
              </div>

              <div className={styles.containersTitle}>Kiertotalouden kuljetukset</div>
                
              <div className={styles.fields}>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>   
                    <span className={styles.gridTitle}>Lavatunniste 1</span>
                    <Field id="container1Unload" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 2</span>
                    <Field id="container2Unload" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 3</span>
                    <Field id="container3Unload" maxLength={20} />
                  </div>
                </div>
                <div className={styles.gridColumn}>
                  <div className={styles.fieldWrapper}>
                    <span className={styles.gridTitle}>Lavatunniste 4</span>
                    <Field id="container4Unload" maxLength={20} />
                  </div>
                </div>
            </div>
            </Form>
          )
        }}
      </Formik>
    );
  }
}

UnloadingDetailsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  loadUnit: PropTypes.string,
  waybillNum: PropTypes.string,
  weightNoteNumberUnloading: PropTypes.string,
  actualAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  container1Unload: PropTypes.string,
  container2Unload: PropTypes.string,
  container3Unload: PropTypes.string,
  container4Unload: PropTypes.string
};

export default UnloadingDetailsInput;
