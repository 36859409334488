const { get } = require("lodash");
const { getOrderQuery } = require("../../../api/graphql/getOrder");
const { default: fetchTransactionInputs } = require("../../../utils/fetchTransactionInputs");

const isSet = (value) => {
  return value !== null && value !== undefined && value !== "";
};

const isWaybillSigned = (signatures, type) => {
  const driverSignatureIsSet = isSet(signatures?.driverSignature);
  const senderSignatureIsSet = isSet(signatures?.senderSignature) || (isSet(signatures?.senderSignatureNotAvailable) && signatures.senderSignatureNotAvailable);
  const receiverSignatureIsSet = isSet(signatures?.receiverSignature) || (isSet(signatures?.receiverSignatureNotAvailable) && signatures.receiverSignatureNotAvailable);

  const result = type === 'load'
    ? driverSignatureIsSet && senderSignatureIsSet
    : driverSignatureIsSet && senderSignatureIsSet && receiverSignatureIsSet;

  return result;
}

const getWaybillTransactionData = (order, isOngoingLoad, isOngoingUnload, isIncompleteUnload) => {
  // Fetch entered transaction  data
  const transactionLoadInputs = fetchTransactionInputs();

  // loading is ongoing. Use the user input values (if available)
  get(order, "rows", []).forEach((row) =>
    row.transactions.forEach((transaction) => {
      // The apporach here is a bit weird and a little redundant with the improved caching
      // in the app. We use local storage to record inputs (waybiull numbers and amounts)
      // for each transaction. This data is then retrieved when the transactionStatus is changed
      // via the Mutation. I think it has originally been used so that users could enter data on
      // various screens and it would be retreived when the Mutation was submitted.

      // At some point this approach may need reworked.

      // First for the transaction in the orderRow, check if there is any waybill/amount data
      // in localstorage for either pickup transaction ID or unload transaction ID.
      const pickupInput = transactionLoadInputs[transaction.pickupTransactionId] || {};
      const unloadInput = transactionLoadInputs[transaction.unloadTransactionId] || {};

      // For pickups users can change BOTH the waybill and amount.
      // For unloading, users can ONLY change the amnount - the waybill is locked to whatever
      // was stored when the goods were loaded into the truck.

      // To set the waybill, first check if the waybill was updated when the goods were loaded (from localstorage).
      // If there is no record in local storage, use the waybill in the data returned from SISU. This value can only
      // be changed by users when goods are loaded. It cannot be changed when goods are unloaded.
      transaction.waybillNum = pickupInput.waybill ? pickupInput.waybill : transaction.waybillNum;

      // For the amount, users can update this when loading & unloading goods. This means a priority order is used
      // to determine which value to use:
      // 1. Use data eneterd for unloading transaction id (only when unloading)
      // 2. Use data eneterd for loading transaction id (both when loading & unloading)
      // 3. Use data from SISU if available.
      if (!isOngoingLoad && !isIncompleteUnload) {
        transaction.actualAmount = transaction.actualAmount || undefined;
      } else if (isOngoingUnload && unloadInput.amountLoaded !== undefined && `${unloadInput.amountLoaded}`.length > 0) {
        transaction.actualAmount = unloadInput.amountLoaded
      } else if (pickupInput.amountLoaded !== undefined && `${pickupInput.amountLoaded}`.length > 0) {
        transaction.actualAmount = pickupInput.amountLoaded
      } else {
        transaction.actualAmount = transaction.actualAmount || undefined;
      }
      // transaction.actualAmount =
      //   isOngoingUnload && unloadInput.amountLoaded !== undefined && `${unloadInput.amountLoaded}`.length > 0
      //     ? unloadInput.amountLoaded
      //     : pickupInput.amountLoaded !== undefined && `${pickupInput.amountLoaded}`.length > 0
      //       ? pickupInput.amountLoaded
      //       : transaction.actualAmount || undefined;

      transaction.amountLoaded = pickupInput.amountLoaded ? pickupInput.amountLoaded : transaction.amountLoaded;
      transaction.amountUnloaded = unloadInput.amountLoaded ? unloadInput.amountLoaded : transaction.amountUnloaded;;

      // WeightNoteNumbers are not loaded from SISU. It is an open non-required field for users to fill in. Load from localstorage if available.
      transaction.weightNoteNumberLoading = pickupInput.weightNoteNumberLoading
        ? pickupInput.weightNoteNumberLoading
        : transaction.weightNoteNumberLoading
          ? transaction.weightNoteNumberLoading
          : "";
      transaction.weightNoteNumberUnloading = unloadInput.weightNoteNumberUnloading
        ? unloadInput.weightNoteNumberUnloading
        : transaction.weightNoteNumberUnloading
          ? transaction.weightNoteNumberUnloading
          : "";

      transaction.container1Load = pickupInput.container1Load
        ? pickupInput.container1Load
        : transaction.container1Load
          ? transaction.container1Load
          : "";
      transaction.container2Load = pickupInput.container2Load
        ? pickupInput.container2Load
        : transaction.container2Load
          ? transaction.container2Load
          : "";
      transaction.container3Load = pickupInput.container3Load
        ? pickupInput.container3Load
        : transaction.container3Load
          ? transaction.container3Load
          : "";
      transaction.container4Load = pickupInput.container4Load
        ? pickupInput.container4Load
        : transaction.container4Load
          ? transaction.container4Load
          : "";
      transaction.container1Unload = unloadInput.container1Unload
        ? unloadInput.container1Unload
        : transaction.container1Unload
          ? transaction.container1Unload
          : "";
      transaction.container2Unload = unloadInput.container2Unload
        ? unloadInput.container2Unload
        : transaction.container2Unload
          ? transaction.container2Unload
          : "";
      transaction.container3Unload = unloadInput.container3Unload
        ? unloadInput.container3Unload
        : transaction.container3Unload
          ? transaction.container3Unload
          : "";
      transaction.container4Unload = unloadInput.container4Unload
        ? unloadInput.container4Unload
        : transaction.container4Unload
          ? transaction.container4Unload
          : "";
    })
  );

  return order;
}

const getWaybillDataForOrder = async (client, destination, orderNum, isOnline, allowActions = true) => {
  const orderData = await client.query({
    query: getOrderQuery,
    variables: { orderId: orderNum },
    fetchPolicy: 'network-only'
  }).then(response => response.data.getOrder ?? {}
  ).catch(error => {
    console.log('Query error', error);
    return {};
  });

  const destinationTransactions = destination.transactions;
  const destinationTransactionIds = getUniqueProps(destinationTransactions, "transactionId");

  const isOngoingLoad = destination.type === "load" && !!destination.ata && !destination.atd;
  const isOngoingUnload =
    !isOngoingLoad && destination.type === "unload" && !!destination.ata && !destination.atd;
  const isIncompleteUnload = destination.type === "unload" && !destination.atd;

  const order = getWaybillTransactionData(orderData, isOngoingLoad, isOngoingUnload, isIncompleteUnload, destinationTransactionIds);

  const transactions = [];
  const rows = order.rows;
  rows &&
    rows.forEach((row) =>
      row.transactions.forEach((transaction) => {
        if (
          destinationTransactionIds.includes(transaction.pickupTransactionId) ||
          destinationTransactionIds.includes(transaction.unloadTransactionId)
        ) {
          transactions.push(transaction);
        }
      })
    );
  const waybillData = {
    id: order.id,
    orderDate: order.orderDate,
    customerName: order.customerName,
    customerOrderNum: order.customerOrderNum,
    customerRefNum: order.customerRefNum,
    orderRefNum: order.orderRefNum,
    additionalInfo: order.additionalInfo,
    delivery: order.delivery,
    rows,
    transactions,
    transactionIds: destinationTransactionIds,
    isOnline,
    type: destination.type,
    customerNum: +order.customerNum,
    deliveryCondition: order.deliveryCondition,
    payerNum: +order.payerNum,
    payerName: order.payerName,
    allowActions,
    isOngoingLoad,
    isOngoingUnload,
    onUpdateLoad: () => { },
    onUpdateUnload: () => { },
    routeId: destination.routeId,
    orderId: order.orderNum,
  };

  return waybillData;
}

module.exports = {
  isWaybillSigned: isWaybillSigned,
  isSet: isSet,
  getWaybillTransactionData: getWaybillTransactionData,
  getWaybillDataForOrder: getWaybillDataForOrder,
};
