import React from "react";
import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react";
import { Router, Redirect } from "@reach/router";

import authAPI from "./api/authAPI";
import getAppSyncClient from "./apollo/client";
import filterByProp from "./utils/filterByProp";
import findReact from "./utils/findReact";
import sendMessageToNativeApp from "./utils/sendMessageToNativeApp";

import DeviceType from "./enum/DeviceType";
import Dashboard from "./containers/Dashboard";
import Authentication from "./containers/Authentication";
import AppManager from "./containers/AppManager";
import StayAwake from "./containers/StayAwake";
import GeoLocated from "./containers/GeoLocated";
import Device from "./containers/data/Device";
import CurrentUser from "./containers/data/CurrentUser";
import Statusbar from "./components/layout/Statusbar";
import ReportManagement from "./containers/reports/ReportManagement";
import UserManagement from "./containers/usermanagement/UserManagement";
import UserSettings from "./containers/UserSettings";
import Sidebar from "./components/layout/Sidebar";
import Vehicles, { CurrentVehicle } from "./containers/data/Vehicles";
import VehicleList from "./components/usermanagement/vehicleList/VehicleList";
import Routes from "./containers/routes/Routes";

import VehicleDestinations from "./containers/data/VehicleDestinations";
import RouteDestinations from "./containers/data/RouteDestinations";
import RouteSource from "./containers/data/RouteSource";
import UpdateVehicleLocation from "./containers/data/UpdateVehicleLocation";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { fiFI } from '@material-ui/core/locale';
import blueGrey from "@material-ui/core/colors/blueGrey";
import lightGreen from "@material-ui/core/colors/lightGreen";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class App extends React.Component {
  state = {
    themeType: 'light',
    appSyncClient: null,
    isNativeApp: !!window.isNativeApp,
    snackbarOpen: false
  }
  componentDidMount() {
    const { GraphQLUrl, Region } = this.props.awsConfig;
    const appSyncClient = getAppSyncClient(
      GraphQLUrl,
      Region
    );
    this.setState({ ...this.state, appSyncClient });
  }

  render() {
    const { awsConfig } = this.props;

    // OAuth properties are required for the Azure AD integration
    const oauth = {
      domain: awsConfig.CognitoUserPoolDomain,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: `${awsConfig.ApplicationUrl}/login`,
      redirectSignOut: `${awsConfig.ApplicationUrl}/logout`,
      // 'code' for Authorization code grant,
      // 'token' for Implicit grant
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    };
    authAPI.configure(
      {
        mandatorySignIn: true,
        region: awsConfig.Region,
        userPoolId: awsConfig.CognitoUserPoolId,
        userPoolWebClientId: awsConfig.CognitoAppClientId,
        oauth: oauth
      },
      async (username, password) => {
        // onSignIn
        sendMessageToNativeApp({
          'operation': 'userLogin',
          'data': {'username': username, 'password': password}
        });
      },
      async () => {
        // onSignOut
        sendMessageToNativeApp({
          'operation': 'userLogout'
        });
        return this.state.appSyncClient.clearStore();
      }
    );

    return (
      <AppManager
        appVersion={awsConfig.ApplicationVersion}
      >
        { isOnline => !!this.state.appSyncClient &&
          <Authentication
            clearCache={this.state.appSyncClient.clearStore}
            render={() => (
              <ApolloProvider client={this.state.appSyncClient}>
                <Rehydrated>
                  <CurrentUser>
                    {currentUser =>
                      currentUser.isAdmin
                        ? this.renderAdminApp({ currentUser, isOnline, awsConfig })
                        : this.renderDriverApp({ currentUser, isOnline })
                    }
                  </CurrentUser>
                </Rehydrated>
              </ApolloProvider>
            )}
          />
        }
      </AppManager>
    );
  }

  // Admins are shown only the user management so there's no need for device type selection or vehicle
  renderAdminApp = ({ isOnline, currentUser, awsConfig }) => (
    <React.Fragment>
      <Statusbar isOnline={isOnline} awsConfig={awsConfig}/>
      <Dashboard>
        <Sidebar user={currentUser} />
        <Router>
          <ReportManagement path="reports/*" />
          <UserManagement path="admin/*" />
          <UserSettings path="user/*" user={currentUser} />
          <Redirect noThrow from="*" to="/admin/drivers" />
        </Router>
      </Dashboard>
    </React.Fragment>
  );

  renderDriverApp = ({ isOnline, currentUser }) => {
    const { isContractor } = currentUser;

    return (
      <RouteSource>
        {({ vehicleId, routeId }) =>
          routeId ? (
            <RouteDestinations routeId={routeId}>
              {/* Spot driver, one route only & automatically set deviceType to vehicle */}
              {({ destinations, refetch, loadingDestinations }) => {

                let deviceType = DeviceType.VEHICLE;
                let vehicleId = "SPOT";

                return (
                  <React.Fragment>
                    {/*
                      <LogCurrentUser>
                        currentUser: currentUser,
                        currentVehicle: "SPOT",
                        deviceType: deviceType
                      </LogCurrentUser>
                    */}
                    <GeoLocated>
                      {({ geolocation }) => (
                        <React.Fragment>
                          {
                            this.renderDashboard({
                              isOnline,
                              currentUser,
                              isContractor,
                              destinations,
                              refetchDestinations: refetch,
                              loadingDestinations,
                              deviceType,
                              geolocation
                            })
                          }
                        </React.Fragment>
                      )}
                    </GeoLocated>
                  </React.Fragment>
                );
              }}
            </RouteDestinations>
          ) : (
              <Device>
                {({ deviceType }) => (
                  <Vehicles>
                    {({ vehicles }) => (
                      <CurrentVehicle vehicles={vehicles}>
                        {({ currentVehicle }) => (
                          <React.Fragment>
                            {/*
                                <RecordLogin>
                                  currentUser: currentUser,
                                  currentVehicle: "SPOT",
                                  deviceType: deviceType
                                </RecordLogin>
                              */}
                            <VehicleDestinations vehicleId={currentVehicle.vehicleId}>
                              {/* Regular driver, one to many routes (all for a single vehicle) */}
                              {({ destinations, refetch, loadingDestinations, completeVehicleDestinationsQueryData }) => {
                                const props = {
                                  isOnline,
                                  currentUser,
                                  vehicles,
                                  isContractor,
                                  destinations,
                                  refetchDestinations: refetch,
                                  loadingDestinations,
                                  completeVehicleDestinationsQueryData
                                };

                                return (
                                  <React.Fragment>
                                    {/*
                                        If the device is in vehicle mode, let's keep it awake and track the location.
                                        If not, we'll just render the app without tracking related components.
                                      */}
                                    {deviceType === DeviceType.VEHICLE ? (
                                      <React.Fragment>
                                        <GeoLocated>
                                          {({ geolocation }) => (
                                            <React.Fragment>
                                              {!this.state.isNativeApp &&
                                                <UpdateVehicleLocation
                                                  interval={300000}
                                                  geolocation={geolocation}
                                                  vehicleId={currentVehicle.vehicleId}
                                                />}
                                              {
                                                this.renderDashboard({
                                                  ...props,
                                                  currentVehicle,
                                                  deviceType,
                                                  geolocation,
                                                })
                                              }
                                            </React.Fragment>
                                          )}
                                        </GeoLocated>
                                      </React.Fragment>
                                    ) : (
                                        this.renderDashboard({
                                          ...props,
                                          deviceType,
                                          currentVehicle
                                        })
                                      )}
                                  </React.Fragment>
                                );
                              }}
                            </VehicleDestinations>
                          </React.Fragment>
                        )}
                      </CurrentVehicle>
                    )}
                  </Vehicles>
                )}
              </Device>
            )
        }
      </RouteSource>
    );
  };

  refreshStyles = () => {
    const applyStyle = function(item) {
      if(item.classList.value.includes('MuiOutlinedInput') ||
        item.classList.value.includes('MuiSwitch-switchBase')) {
          return false;
      } else return true;
    }
    const themeType = this.state.themeType;
    // eslint-disable-next-line
    const backgroundNodes = document.querySelectorAll("\
      [class*=Sidebar], \
      [class*=Schedule_root], \
      [class*=DestinationDetails], \
      [class*=DestinationOrders], \
      [class*=DestinationWrapper_root], \
      [class*=VehicleDetails], \
      [class*=MuiTable], \
      [class*=jss], \
      [class*=Panel], \
      [class*=Modal_modal], \
      [class*=UserSettings], \
      footer, \
      form, \
      aside");

    let backgroundColor = 'white';
    if (themeType === 'dark') {
      backgroundColor = '#303030';
    }

    backgroundNodes.forEach(function (item) {
      if(applyStyle(item)) {
        item.style.backgroundColor = backgroundColor;
      }
    });

    // eslint-disable-next-line
    const textNodes = document.querySelectorAll("\
      [class*=Sidebar], \
      [class*=ScheduleDestination_destination], \
      [class*=ScheduleDestination_time], \
      [class*=ScheduleDestination_city], \
      [class*=Schedule_completed], \
      [class*=DestinationDetails_location], \
      [class*=DestinationOrder], \
      [class*=OrderDetails], \
      [class*=MuiTable], \
      [class*=jss], \
      [class*=Modal], \
      [class*=UserSettings_heading], \
      [class*=UserSettings_fields], \
      [class*=GreenField_field], \
      h2, \
      [class*=DateLabel_root], \
      [class*=VehicleDetails]");

    let textColor = 'black';
    if (themeType === 'dark') {
      textColor = 'white';
    }
    textNodes.forEach(function (item) {
      if(applyStyle(item)) {
        item.style.color = textColor;
      }
    });

    // eslint-disable-next-line
    const secondaryTextNodes = document.querySelectorAll("\
      [class*=UserSettings_disabled], \
      [class*=VehicleDetails_gridTitle], \
      [class*=AdditionalInfo], \
      [class*=ExceptionForm], \
      legend");

    let secondaryTextColor = '#45555FB3';
    if (themeType === 'dark') {
      secondaryTextColor = 'white';
    }
    secondaryTextNodes.forEach(function (item) {
      if(applyStyle(item)) {
        item.style.color = secondaryTextColor;
      }
    });

    // eslint-disable-next-line
    const textInputBackgroundNodes = document.querySelectorAll("\
      [class*=ExceptionForm_fieldmultiline], \
      [class*=ExceptionForm_dateFieldContainer], \
      [class*=GreenField_field]");

    let textInputBackgroundColor = 'white';
    if (themeType === 'dark') {
      textInputBackgroundColor = '#45555FB3';
    }
    textInputBackgroundNodes.forEach(function (item) {
      if(applyStyle(item)) {
        item.style.backgroundColor = textInputBackgroundColor;
      }
    });

  };

  changeTheme = (e) => {
    e.preventDefault();
    const themeType = this.state.themeType === 'light' ? 'dark' : 'light';
    this.setState({ ...this.state, themeType: themeType });
    const map = document.getElementById('here-map');
    if (map) {
      findReact(map).toggleDayNightMode(themeType);
    }
  };

  /**
   * Show snackbar
   * This is fired from Heremaps.js when user presses the zoom control buttons
   */
  showSnackbar = () => {
    this.setState({snackbarOpen: true});
  }

  /**
   * Handle snackbar close
   * This is fired from Heremaps.js after a 5sec timeout period after opening it to automatically close the snackbar
   */
  handleSnackbarClose = () => {
    this.setState({snackbarOpen: false});
  }

  renderDashboard = ({
    isOnline,
    currentUser,
    currentVehicle,
    deviceType,
    geolocation,
    isContractor,
    destinations,
    refetchDestinations,
    loadingDestinations,
    completeVehicleDestinationsQueryData
  }) => {
    const { awsConfig } = this.props;
    const upcomingDestinations = filterByProp(
      destinations,
      "status",
      "upcoming"
    );

    let theme = createTheme({
      typography: {
        useNextVariants: true,
      },
      palette: {
        primary: {
          light: lightGreen[300],
          main: lightGreen[500],
          dark: lightGreen[700]
        },
        secondary: {
          light: blueGrey[300],
          main: blueGrey[500],
          dark: blueGrey[700]
        },
        type: this.state.themeType
      }
    }, fiFI);

    return (
      <MuiThemeProvider theme={theme}>
        <StayAwake geolocation={geolocation ? geolocation : null} />
        <Statusbar isOnline={isOnline} awsConfig={awsConfig}>
          {currentVehicle && <span>Auto: {currentVehicle.licenseNum}</span>}
          {(!isOnline) && <span>EI INTERNETYHTEYTTÄ</span>}
          {(isOnline && geolocation && geolocation.isTracking && geolocation.geolocated) && <span>GPS: On</span>}
          {(isOnline && geolocation && geolocation.isTracking && !geolocation.geolocated) && <span>GPS: Off</span>}
          {(isOnline && deviceType !== DeviceType.VEHICLE) && <span>EI KULJETUKSEN SEURANTAA</span>}
        </Statusbar>
        <Snackbar open={this.state.snackbarOpen}>
            <Alert severity="info">
              Automaattinen lähestyminen kytketty pois päältä.
            </Alert>
        </Snackbar>
        <Dashboard>
          <Sidebar
            deviceType={deviceType}
            user={currentUser}
            vehicle={currentVehicle}
            geolocation={geolocation}
            numOfUpcoming={upcomingDestinations.length}
            changeTheme={this.changeTheme}
          />
          <Router>
            {isContractor && <UserManagement path="admin/*" refreshStyles={this.refreshStyles} />}
            <VehicleList path="vehicle/" refreshStyles={this.refreshStyles} />

            <Routes
              path="routes/*"
              destinations={destinations}
              deviceType={deviceType}
              currentUser={currentUser}
              currentVehicle={currentVehicle}
              refreshRoutes={refetchDestinations}
              destinationsUpdating={loadingDestinations}
              geolocation={geolocation}
              refreshStyles={this.refreshStyles}
              mapMode={this.state.themeType}
              isOnline={isOnline}
              currentVehicleLicenseNum={currentVehicle && currentVehicle.licenseNum}
              completeVehicleDestinationsQueryData={completeVehicleDestinationsQueryData}
              handleSnackbarClose={this.handleSnackbarClose}
              showSnackbar={this.showSnackbar}
            />
            {isContractor && <ReportManagement path="reports/*" refreshStyles={this.refreshStyles} />}
            <UserSettings path="user/*" user={currentUser} refreshStyles={this.refreshStyles} />
            <Redirect noThrow from="*" to="/routes" />
          </Router>
        </Dashboard>
      </MuiThemeProvider>
    );
  };
}

export default App;
