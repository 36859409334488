import { openDB } from 'idb';

const dbPromise = openDB('rahtari', 1, {
  upgrade(db) {
    db.createObjectStore('waybillImages');
  },
});

// Remove images older than 3 days
const removeOldImages = async () => {
  const db = await dbPromise;
  const keys = await db.getAllKeys('waybillImages');
  const now = Date.now();
  const oldKeys = keys.filter(key => {
    const object = db.get('waybillImages', key);
    return now - object.timestamp > 1000 * 60 * 60 * 24 * 3; // 3 days
  });
  return Promise.all(oldKeys.map(key => db.delete('waybillImages', key)));
}

const constructKey = (destinationId, orderNum) => `${destinationId}-${orderNum}`;

/*
  * Use destinationId-orderNum as key
*/
export const setImages = async (destinationId, orderNum, imageBlob) => {
  // first delete all data.. removeOldImages should probably be run with interval, but for now we'll call it here without await
  removeOldImages();
  const key = constructKey(destinationId, orderNum);
  const db = await dbPromise;
  const object = {
    imageData: imageBlob,
    timestamp: Date.now(),
  }
  return db.put('waybillImages', object, key);
};

export const getImages = async (destinationId, orderNum) => {
  const key = constructKey(destinationId, orderNum);
  const db = await dbPromise;
  const object = await db.get('waybillImages', key);
  return object?.imageData;
};

export const deleteImages = async (destinationId, orderNum) => {
  const key = constructKey(destinationId, orderNum);
  const db = await dbPromise;
  return db.delete('waybillImages', key);
};