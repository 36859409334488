import React from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";
import deepEqual from "lodash/isEqual";
import moment from "moment";

import { navigate } from "@reach/router";

import filterByProp from "../../../utils/filterByProp";

import NavBar from "../../layout/NavBar";
import NavLink from "../../layout/NavLink";
import DateLabel from "./DateLabel";
import ScheduleDestination from "./ScheduleDestination";

import IconButton from "../../input/IconButton";

import iconRefresh from "../assets/schedule-refresh--white.svg";
import iconExRoute from "../assets/schedule-route-exception--red.svg";
import iconExSecurity from "../assets/schedule-security-exception--red.svg";

import styles from "./Schedule.module.scss";

// Order the destination by eta and group them by date inside an object
const getDestinationsByDates = memoize(
  destinations =>
    destinations
      .sort((a, b) =>
        moment(a.eta, "DD-MM-YYYY HH-mm").diff(
          moment(b.eta, "DD-MM-YYYY HH-mm")
        )
      )
      .reduce((acc, destination) => {
        const date = moment(destination.eta, "DD-MM-YYYY").format("DD-MM-YYYY");
        if (!acc[date]) acc[date] = [];
        acc[date].push(destination);
        return acc;
      }, {}),
  deepEqual
);

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomingDestinations: [],
      destinations: []
    };
  }

  static getDerivedStateFromProps(props) {
    const { status, destinations } = props;
    const upcomingDestinations = filterByProp(
      destinations,
      "status",
      "upcoming"
    );

    return {
      upcomingDestinations,
      destinations:
        status === "upcoming"
          ? upcomingDestinations
          : filterByProp(destinations, "status", status)
    };
  }

  componentDidMount() {
    this.props.refreshRoutes();
  }

  componentDidUpdate() {
    this.props.refreshStyles();
  }

  render() {
    const {
      status,
      dismissCancelledDestination,
      refreshRoutes,
      destinationsUpdating,
      allowExceptions,
      refreshStyles
    } = this.props;
    const { upcomingDestinations, destinations } = this.state;

    const numOfUpcoming = upcomingDestinations.filter(
      destination => !destination.atd
    ).length;

    let destinationsByDate = getDestinationsByDates(destinations);

    if (status === "completed") {
      let reversedDestinationsByDate = {};
      const keys = Object.keys(destinationsByDate).reverse();
      keys.forEach(key => reversedDestinationsByDate[key] = destinationsByDate[key]);
      destinationsByDate = reversedDestinationsByDate;
    }

    return (
      <div className={styles.root}>
        <NavBar>
         <div className={styles.buttonWrapper}>
         {allowExceptions && (
            <div className={styles.buttonContainer}>
              <IconButton
              onClick={() => {
                navigate('/routes/exception');
                window.location.reload();
              }}
              isUpdating={destinationsUpdating}
              // refreshStyles={refreshStyles}
              icon={iconExRoute}
              className="exception"
              text="Poikkeama"
              />
            </div>
          )}
          {allowExceptions && (
            <div className={styles.buttonContainer}>
              <IconButton
              onClick={() => {
                navigate('/routes/security');
              }}
              isUpdating={destinationsUpdating}
              // refreshStyles={refreshStyles}
              icon={iconExSecurity}
              className="exception"
              text="Turvallisuus&shy;havainto"
              />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <IconButton
                onClick={() => {
                  refreshRoutes();
                }}
                isUpdating={destinationsUpdating}
                icon={iconRefresh}
                className=""
                text="Päivitä ajot"
              />
            </div>
          </div>
          <NavLink to="../upcoming">
            Tulevat ajot
            {numOfUpcoming > 0 && (
              <span
                className="routeAmount"
                style={numOfUpcoming > 9 ? { letterSpacing: "-1px" } : null}
              >
                {numOfUpcoming > 9 ? "9+" : numOfUpcoming}
              </span>
            )}
          </NavLink>
          <NavLink to="../completed">Menneet ajot</NavLink>
        </NavBar>

        {status === "completed" && (
          <header className={styles.completedHeader}>
            <span className={styles.time}>Aika</span>
            <span className={styles.type}>Tapahtuma</span>
            <span className={styles.destination}>Käyntipaikka</span>
          </header>
        )}

        {Object.entries(destinationsByDate).map(([date, destinations]) => (
          <div key={date} className={styles[status]}>
            <DateLabel dateString={date} status={status} />
            <div className={`${styles.destinations} ${styles[status]}`}>
              {destinations.map(destination => (
                <ScheduleDestination
                  key={JSON.stringify(destination)}
                  status={status}
                  destination={destination}
                  dismissCancelled={dismissCancelledDestination}
                  refreshStyles={refreshStyles}
                />
              ))}
            </div>
          </div>
        ))}
        {status === "upcoming" && (
          <div className={styles.noMore}>Ei vielä muita ajoja</div>
        )}
      </div>
    );
  }
}

Schedule.propTypes = {
  status: PropTypes.oneOf(["upcoming", "completed"]),
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number,
      eta: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  dismissCancelledDestination: PropTypes.func.isRequired,
  refreshRoutes: PropTypes.func.isRequired,
  destinationsUpdating: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool
};

export default Schedule;
