import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import DestinationFooter from "./DestinationFooter";

import styles from "./DestinationWrapper.module.scss";
import { WaybillDataShape } from "../order/shapes";

const DestinationWrapper = ({
  allowActions,
  allowExceptions,
  children,
  className,
  client,
  currentVehicle,
  destination,
  headerLinkTarget,
  headerLinkText,
  id,
  isLoading,
  isOnline,
  newWaybillNumber,
  orderView,
  refreshRoutes,
  toggleState,
  waybillData,
}) => {
  const headerText = `${destination.type === "load" ? "Lastaus" : "Purku"} – ${destination.name}`;

  return (
    <div
      className={`${styles.wrapper} ${styles[className]} ${destination.state === "completed"
        ? styles.completed
        : destination.state === "ongoing"
          ? destination.type === "load"
            ? styles.loading
            : styles.unloading
          : null
        }`}
    >
      <header>
        {headerLinkTarget && (
          <Link to={headerLinkTarget}>{headerLinkText}</Link>
        )}
        <span>{headerText}</span>
      </header>
      <div className={`${styles.root}`}>
        <div className={styles.scroll}>{children}</div>
      </div>

      <DestinationFooter
        allowActions={allowActions}
        allowExceptions={allowExceptions}
        appSyncClient
        ata={destination.ata}
        atd={destination.atd}
        client={client}
        currentVehicle={currentVehicle}
        destinationId={destination.id}
        eta={destination.eta}
        etd={destination.etd}
        id={id}
        isLoading={isLoading}
        isOnline={isOnline}
        newWaybillNumber={newWaybillNumber}
        onAction={() => {
          toggleState(destination);
        }}
        orderView={orderView}
        refreshRoutes={refreshRoutes}
        routeId={destination.routeId}
        state={destination.state}
        transactions
        type={destination.type}
        waybillData={waybillData}
      />
    </div>
  );
};

DestinationWrapper.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentVehicle: PropTypes.object,
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    state: PropTypes.oneOf([
      "new",
      "updated",
      "cancelled",
      "completed",
      "ongoing"
    ])
  }).isRequired,
  headerLinkTarget: PropTypes.string,
  headerLinkText: PropTypes.string,
  isLoading: PropTypes.bool,
  isOnline: PropTypes.bool,
  newWaybillNumber: PropTypes.string,
  refreshRoutes: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,  isLoading: PropTypes.bool,
  waybillData: PropTypes.shape(WaybillDataShape),
  waybillImages: PropTypes.arrayOf(PropTypes.string),
};

DestinationWrapper.defaultPropTypes = {
  headerLink: "../",
  headerLinkText: "Takaisin",
  className: "",
  isLoading: false,
  isOnline: false,
  waybillImages: [],
};

export default DestinationWrapper;
